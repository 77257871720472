import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import {
  RiskBasicInfo,
  RiskSummary,
  RiskEditManagement,
} from "@aim-mf/risk-edit";
import domtoimage from "dom-to-image";

const exportRisk = (finish, updateExportProgress) => {
  let pdf = new jsPDF("p", "px", "a4");
  let imageCount = 0;
  let converted = 0;
  let converting = 0;
  const children = Array.from(document.getElementById("Export_PDF").children);
  const tasks = children.map((div) => {
    div.querySelectorAll("*").forEach((tag) => {
      if (
          tag.style.boxShadow !== undefined &&
          tag.style.boxShadow !== null
      ) {
        tag.style.boxShadow = "";
      }
      if (
        tag.textContent === "Create Task" ||
        tag.textContent.startsWith("Last edited by")
      ) {
        tag.style.whiteSpace = "nowrap";
      }
    });
    converting = converting + 1;
    updateExportProgress(converting + converted + imageCount);

    return domtoimage.toJpeg(div, { quality: 0.95 }).then((dataUrl) => {
      converted = converted + 1;
      updateExportProgress(converting + converted + imageCount);

      let htmlImage = new Image();
      htmlImage.onload = () => {
        pdf.addPage([htmlImage.width, htmlImage.height]);
        pdf.addImage(htmlImage, 0, 0, htmlImage.width, htmlImage.height);

        imageCount = imageCount + 1;
        updateExportProgress(converting + converted + imageCount);

        if (imageCount === children.length) {
          pdf.deletePage(1);
          pdf.save("Download.pdf");
          finish();
        }
      };
      htmlImage.src = dataUrl;
    });
  });
};

class ExportPDF extends React.Component {
  constructor() {
    super();
    this.state = {
      typeList: [
        { name: "sustainability", label: "Sustainability" },
        { name: "ABC", label: "ABC" },
        { name: "fraud", label: "Fraud" },
        { name: "dataPrivacy", label: "Data Privacy" },
        { name: "cyberSecurity", label: "Cyber Security" },
        { name: "criticalInfra", label: "Critical Infra" },
        { name: "others", label: "Others" },
      ],
      currentUser: {},
      title: "",
    };
  }

  decodeRiskUser = (riskDetail) => {
    let newRiskDetail = this.decodeUserDetail(
      "controls",
      JSON.parse(JSON.stringify(riskDetail))
    );
    newRiskDetail = this.decodeUserDetail("KRIs", newRiskDetail);
    newRiskDetail = this.decodeUserDetail("actionplans", newRiskDetail);
    newRiskDetail = this.decodeUserDetail("owner", newRiskDetail);
    newRiskDetail = this.decodeUserDetail("delegate", newRiskDetail);
    newRiskDetail = this.decodeUserDetail("riskappetite", newRiskDetail);
    newRiskDetail = this.APStatusGenerated(newRiskDetail);
    return newRiskDetail;
  };
  APStatusGenerated = (riskDetail) => {
    let newRiskDetail = riskDetail;
    newRiskDetail.actionplans.forEach((AP) => {
      if (new Date() > new Date(AP.deadline + " 23:59:59")) {
        AP.status = "Overdue";
      } else if (new Date() < new Date(AP.deadline + " 00:00:00")) {
        AP.status = "On Track";
      } else {
        AP.status = "In Progress";
      }
    });
    return newRiskDetail;
  };
  decodeUserDetail = (field, riskDetail) => {
    let newRiskDetail = riskDetail;
    if (field === "owner") {
      newRiskDetail.owner.forEach((username, index) => {
        let ownerDetail = this.props.userList.filter((user) => {
          return user.username === username;
        });
        newRiskDetail.owner[index] = ownerDetail[0];
      });
    } else if (field === "delegate") {
      newRiskDetail.delegate.forEach((username, index) => {
        let ownerDetail = this.props.userList.filter((user) => {
          return user.username === username;
        });
        newRiskDetail.delegate[index] = ownerDetail[0];
      });
    } else if (field === "riskappetite") {
      newRiskDetail[field].forEach((item) => {
        item.provided_by.forEach((username, index) => {
          let ownerdetail = this.props.userList.filter((user) => {
            return user.username === username;
          });
          item.provided_by[index] = ownerdetail[0];
        });
      });
    } else {
      newRiskDetail[field].forEach((item) => {
        item.owner.forEach((username, index) => {
          let ownerdetail = this.props.userList.filter((user) => {
            return user.username === username;
          });
          item.owner[index] = ownerdetail[0];
        });
      });
    }
    return newRiskDetail;
  };
  componentDidMount() {
    setTimeout(() => {
      exportRisk(this.props.finish, this.props.updateExportProgress);
    }, 500);
  }

  render() {
    return (
      <div id="Export_PDF" style={{ position: "absolute", left: "3000px" }}>
        {this.props.export_risk_detail_list.map((risk_detail_raw) => {
          let risk_detail = this.decodeRiskUser(risk_detail_raw);
          return (
            <div>
              <RiskBasicInfo
                isSubEntity={false}
                parentEntityRisks={this.props.parentEntityRisks}
                riskDetail={risk_detail}
                userList={this.props.userList}
                rights={{ edit: 0, approve: 0 }}
                disableSubmit={true}
                disableSendDelegate={true}
                disableSendOwner={true}
                disableSaveChange={true}
                riskTypeList={this.state.typeList}
                export={true}
              />
              <RiskSummary
                lastEdit={
                  risk_detail.createdUser === "SYSTEM"
                    ? "SYSTEM"
                    : this.props.userList.filter((a) => {
                        return a.username === risk_detail.createdUser;
                      })[0].display_name
                }
                riskDetail={risk_detail}
                rights={{ edit: 0, approve: 0 }}
                export={true}
              />
              <RiskEditManagement
                lastEdit={
                  risk_detail.createdUser === "SYSTEM"
                    ? "SYSTEM"
                    : this.props.userList.filter((a) => {
                        return a.username === risk_detail.createdUser;
                      })[0].display_name
                }
                riskDetail={risk_detail}
                rights={{ edit: 0, approve: 0 }}
                export={true}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export { ExportPDF };
