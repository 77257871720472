import React from "react";
import { Form, Field } from "@progress/kendo-react-form";
import {
  ButtonSolid,
  mapping,
  NonValidationTextInput,
  NonValidationInput,
  DropDownListButton,
  RadioButtonGroup,
  MultiSelector,
  SearchAutoComplete,
  CustomizedCheckBox,
  riskManagementStyle,
} from "@aim-mf/styleguide";

const riskCategoryList = [
  "Strategic",
  "Operational",
  "Financial",
  "Technology",
  "Compliance",
];

class NewRiskForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeList: [
        { name: "sustainability", label: "Sustainability" },
        { name: "ABC", label: "ABC" },
        { name: "fraud", label: "Fraud" },
        { name: "dataPrivacy", label: "Data Privacy" },
        { name: "cyberSecurity", label: "Cyber Security" },
        { name: "criticalInfra", label: "Critical Infra" },
        { name: "others", label: "Others" },
      ],
      others: false,
      sustainability: false,
      ABC: false,
      fraud: false,
      dataPrivacy: false,
      cyberSecurity: false,
      criticalInfra: false,
      level: "parent",
      number: "",
      rskName: "",
      tierList: [1, 2, 3],
      newRiskTier: 1,
      categoryList: riskCategoryList,
      newRiskCategory: "Strategic",
      parentRikList: this.props.parentRiskList.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      }),
      parentRisk: {},
      riskDescription: "",
      owner: [],
      riskList: this.props.riskList,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.riskAutoPopulateDetail !== this.props.riskAutoPopulateDetail
    ) {
      let parentRisk = nextProps.riskAutoPopulateDetail.isParentRisk
        ? {}
        : this.props.riskList.filter((risk) => {
            return (
              risk.sourceRiskID ===
              nextProps.riskAutoPopulateDetail.parentRisk_id
            );
          })[0];

      let categoryList = nextProps.riskAutoPopulateDetail.isParentRisk
        ? riskCategoryList
        : [nextProps.riskAutoPopulateDetail.category];

      let tierList = nextProps.riskAutoPopulateDetail.isParentRisk
        ? [1, 2, 3]
        : [1, 2, 3].slice(
            this.props.riskList.filter((risk) => {
              return (
                risk.sourceRiskID ===
                nextProps.riskAutoPopulateDetail.parentRisk_id
              );
            })[0].tier - 1
          );

      let riskOwner = this.props.userList.filter((user) => {
        return nextProps.riskAutoPopulateDetail.owner.includes(user.id);
      });

      this.setState({
        others: nextProps.riskAutoPopulateDetail.others,
        sustainability: nextProps.riskAutoPopulateDetail.sustainability,
        ABC: nextProps.riskAutoPopulateDetail.ABC,
        fraud: nextProps.riskAutoPopulateDetail.fraud,
        dataPrivacy: nextProps.riskAutoPopulateDetail.dataPrivacy,
        cyberSecurity: nextProps.riskAutoPopulateDetail.cyberSecurity,
        criticalInfra: nextProps.riskAutoPopulateDetail.criticalInfra,
        number: nextProps.riskAutoPopulateDetail.riskNumber,
        level: nextProps.riskAutoPopulateDetail.isParentRisk ? "parent" : "sub",
        rskName: "",
        tierList: tierList,
        newRiskTier: nextProps.riskAutoPopulateDetail.tier,
        categoryList: categoryList,
        newRiskCategory: nextProps.riskAutoPopulateDetail.category,
        parentRikNameList: this.props.parentRiskList.map((a) => a.name),
        parentRisk: parentRisk,
        riskDescription: nextProps.riskAutoPopulateDetail.description,
        owner: riskOwner,
      });
    }
  }
  handleDescriptionTextInputChange = (value) => {
    this.setState({ riskDescription: value });
  };
  handleRiskNumberChange = (e) => {
    this.setState({ number: e.value });
  };
  render() {
    const handleChange = (rows) => {
      this.setState({ rows: rows });
    };

    const handleSubmit = (dataItem) => {
      // construct submit package
      dataItem.number = this.state.number;
      // if (!(this.state.it_risk || this.state.non_it_risk)) {
      //   alert("please specify the risk type");
      //   return;
      // }
      if (dataItem.number === "") {
        alert("Risk Code can not be empty");
        return;
      }
      if (dataItem.name === "") {
        alert("Risk name can not be empty");
        return;
      }
      if (dataItem.RiskDescription === "") {
        alert("Risk description can not be empty");
        return;
      }

      if (dataItem.level === "sub") {
        if (!dataItem.RiskParent) {
          dataItem.RiskParent = this.state.parentRikNameList[0];
        }
        if (!dataItem.category) {
          dataItem.category = this.state.riskList.filter((risk) => {
            return risk.name === dataItem.RiskParent;
          })[0].category;
        }
      } else {
        if (!dataItem.category) {
          dataItem.category = riskCategoryList[0];
        }
      }

      // console.log(dataItem)
      let parentRiskId;
      if (dataItem.level === "sub") {
        parentRiskId = dataItem.RiskParent.id
          ? dataItem.RiskParent.id
          : dataItem.RiskParent;
      }
      let riskCategoryId = riskCategoryList.findIndex((value) => {
        return value === dataItem.category;
      });
      // apply offset of the category ID
      riskCategoryId = riskCategoryId + 1;
      //console.log(dataItem.category)
      var dataPackage = {
        data: {
          others: this.state.others,
          sustainability: this.state.sustainability,
          ABC: this.state.ABC,
          fraud: this.state.fraud,
          dataPrivacy: this.state.dataPrivacy,
          cyberSecurity: this.state.cyberSecurity,
          criticalInfra: this.state.criticalInfra,
          number: dataItem.number,
          name: dataItem.name.trim(),
          description: dataItem.RiskDescription,
          category_id: riskCategoryId,
          tier: dataItem.tier,
          isParentRisk: dataItem.level === "parent",
          parentRisk_id: dataItem.level === "parent" ? null : parentRiskId,
          owner: dataItem.owner || [],
        },
      };
      //console.log(dataPackage);

      this.props.handleNewRiskSubmit(dataPackage);
      this.props.closeCreationForm();
    };

    const onTierChange = (event) => {
      this.setState({
        newRiskTier: event.target.value,
      });
    };

    const onRiskTypeChange = (type) => {
      this.setState({
        [type]: !this.state[type],
      });
    };

    const onCategoryChange = (event) => {
      this.setState({
        newRiskCategory: event.target.value,
      });
    };

    const onRadioGroupChange = (event) => {
      var riskLevel = event.value;
      //console.log(this.props.parentRiskList);
      if (riskLevel === "sub") {
        let parentRiskListExist = this.props.parentRiskList.length !== 0;
        // subrisk, need to update category and parentRiskname
        this.setState({
          parentRikNameList: this.props.parentRiskList.map((a) => a.name),
          parentRisk: this.props.parentRiskList[0],
          categoryList: parentRiskListExist
            ? [this.props.parentRiskList[0].category]
            : riskCategoryList,
          newRiskCategory: parentRiskListExist
            ? this.props.parentRiskList[0].category
            : riskCategoryList[0],
        });
      } else if (riskLevel === "parent") {
        this.setState({
          categoryList: riskCategoryList,
          newRiskCategory: riskCategoryList[0],
        });
      }

      this.setState({ level: riskLevel });
    };

    const onParentRiskChange = (event) => {
      //console.log(event)
      var parentRisk = event.value;
      //console.log([1, 2, 3].slice(parentRisk.tier - 1))
      this.setState({
        newRiskTier: parentRisk.tier,
        tierList: [1, 2, 3].slice(parentRisk.tier - 1),
        categoryList: [parentRisk.category],
        newRiskCategory: parentRisk.category,
      });
    };

    return (
      <div style={riskManagementStyle.formBgStyle}>
        <div style={{ padding: "1rem" }}>
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              sustainability: false,
              ABC: false,
              fraud: false,
              dataPrivacy: false,
              cyberSecurity: false,
              criticalInfra: false,
              number: "",
              name: "",
              RiskDescription: "",
              tier: 1,
              RiskParent: this.state.parentRikList[0],
              level: "parent",
            }}
            render={(formRenderProps) => {
              return (
                <form onSubmit={formRenderProps.onSubmit}>
                  <fieldset style={{ border: "none", width: "31.875rem" }}>
                    <div style={riskManagementStyle.titleSpacingStyle}>
                      <legend style={mapping["heading/h5/lightleft"]}>
                        Create New Risk
                      </legend>
                    </div>
                    <Field
                      title={"Risk Level"}
                      data={radioGroupData}
                      name={"level"}
                      component={RadioButtonGroup}
                      CustomOnChange={onRadioGroupChange}
                      value={this.state.level}
                      populate_id={this.props.riskAutoPopulateDetail.id}
                    />
                    <div style={riskManagementStyle.RadioButtonSpace} />
                    <div style={riskManagementStyle.FieldSpacingStyle}>
                      <Field
                        title={"Risk Code"}
                        value={this.state.number}
                        name={"number"}
                        filterKey={"number"}
                        component={NonValidationInput}
                        fieldstyle={{
                          ...mapping["forms/input-default"],
                          ...riskManagementStyle.FieldTextAreaStyle,
                        }}
                        onChange={this.handleRiskNumberChange}
                      />
                    </div>
                    <div style={riskManagementStyle.FieldSpacingStyle}>
                      <Field
                        title={"Risk Name"}
                        data={this.props.groupRiskList.map(({ id, name }) => ({
                          id,
                          name,
                        }))}
                        name={"name"}
                        filterKey={"name"}
                        component={SearchAutoComplete}
                        handleSearchGroupRisk={this.props.handleSearchGroupRisk}
                        handleriskDetailRequest={
                          this.props.handleriskDetailRequest
                        }
                        searching={this.props.searching}
                      />
                    </div>

                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <Field
                          title={"Risk Tier"}
                          data={this.state.tierList}
                          name={"tier"}
                          component={DropDownListButton}
                          CustomOnChange={onTierChange}
                          DropDownButtonStyle={
                            riskManagementStyle.TierDropDownButtonStyle
                          }
                          value={this.state.newRiskTier}
                          populate_id={this.props.riskAutoPopulateDetail.id}
                        />
                      </div>
                      <div style={riskManagementStyle.DropDownListSpace} />
                      <div>
                        <Field
                          title={"Risk Category"}
                          data={this.state.categoryList}
                          name={"category"}
                          component={DropDownListButton}
                          CustomOnChange={onCategoryChange}
                          DropDownButtonStyle={
                            this.state.level === "sub"
                              ? riskManagementStyle.CategoryDropDownButtonDisableStyle
                              : riskManagementStyle.CategoryDropDownButtonStyle
                          }
                          value={this.state.newRiskCategory}
                          populate_id={this.props.riskAutoPopulateDetail.id}
                          disabled={this.state.level === "sub"}
                        />
                      </div>

                      <div style={riskManagementStyle.DropDownListSpace} />
                      {this.state.level === "sub" && (
                        <div>
                          <Field
                            title={"Risk Parent"}
                            data={this.state.parentRikList}
                            dataDisplayKey={"name"}
                            name={"RiskParent"}
                            component={DropDownListButton}
                            CustomOnChange={onParentRiskChange}
                            DropDownButtonStyle={
                              riskManagementStyle.ParentRiskDropDownButtonStyle
                            }
                            value={this.state.parentRisk}
                            populate_id={this.props.riskAutoPopulateDetail.id}
                          />
                        </div>
                      )}
                    </div>
                    <div style={{ height: "2rem" }} />
                    <div style={riskManagementStyle.formTitleStyle}>
                      Risk Type
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        color: "white",
                      }}
                    >
                      {this.state.typeList.map((type) => {
                        return (
                          <div
                            style={{
                              paddingRight: "1.3rem",
                              flex: "0 0 33.33%",
                            }}
                          >
                            <Field
                              name={type.name}
                              label={type.label}
                              component={CustomizedCheckBox}
                              onCheckChange={() => {
                                onRiskTypeChange(type.name);
                              }}
                              value={this.state[type.name]}
                              checkBoxWrapperStyle={{ marginLeft: "1.25rem" }}
                              populate_id={this.props.riskAutoPopulateDetail.id}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ height: "2rem" }} />
                    <div style={riskManagementStyle.FieldSpacingStyle}>
                      <Field
                        title={"Risk Description"}
                        name={"RiskDescription"}
                        rows={5}
                        placeholder={"Enter your text here..."}
                        changerow={handleChange}
                        minrows={5}
                        maxrows={10}
                        lineheight={22}
                        component={NonValidationTextInput}
                        fieldstyle={{
                          ...mapping["forms/input-default"],
                          ...riskManagementStyle.FieldTextAreaStyle,
                        }}
                        value={this.state.riskDescription}
                        populate_id={this.props.riskAutoPopulateDetail.id}
                        CustomizedOnChange={
                          this.handleDescriptionTextInputChange
                        }
                      />
                    </div>
                    <Field
                      title={"Risk Owner"}
                      userList={this.props.userList}
                      value={this.state.owner}
                      populate_id={this.props.riskAutoPopulateDetail.id}
                      name={"owner"}
                      component={MultiSelector}
                    />
                  </fieldset>
                  <div style={riskManagementStyle.ButtonStyle}>
                    <div>
                      <ButtonSolid
                        name="cancel"
                        height="2.5rem"
                        width="8rem"
                        color="Primary"
                        clickEvent={() => {
                          this.props.closeCreationForm();
                        }}
                      />
                    </div>
                    <div>
                      <ButtonSolid
                        name="create"
                        height="2.5rem"
                        width="8rem"
                        color="Primary"
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const radioGroupData = [
  { label: "Parent Risk", value: "parent", className: "task blue" },
  { label: "Sub Risk", value: "sub", className: "task blue" },
];

export { NewRiskForm };
