import React from "react";
import {
  mapping,
  Loading,
  ButtonSolid,
  riskManagementStyle,
  Excel_export_preview,
  PDF_export_preview,
} from "@aim-mf/styleguide";
import { NewRiskForm } from "./newRiskForm";
import { RiskTable } from "./riskTable";
import { ExportPDF } from "./export";

class RiskManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createNewRisk: false,
      exportRisk: false,
      exportRiskIDList: [],
      exportFormat: "excel",
      startExportingPDF: false,
      exportProgress: 0,
    };
  }

  exportRiskHandler = (idList) => {
    // this.props.handleUserListRequest();
    this.setState({ exportRisk: true, exportRiskIDList: idList });
  };

  createRiskHandler = () => {
    // this.props.handleUserListRequest();
    this.setState({ createNewRisk: true });
  };

  closeCreationForm = () => {
    this.setState({ createNewRisk: false });
  };

  render() {
    let parentRisk = this.props.riskList.filter((risk) => {
      return risk.isParentRisk;
    });
    return (
      <div>
        <div
          style={
            this.state.createNewRisk ||
            this.props.loading ||
            this.state.exportRisk
              ? riskManagementStyle.BlurEffectStyle
              : { dummy: "dummy" }
          }
        >
          <div style={riskManagementStyle.riskTableWrapper}>
            <div style={riskManagementStyle.tableTitlePadding}>
              <div style={riskManagementStyle.titleStyle}>Risk Inventory</div>
              <div className="risk-card-subtitle">
                This manages and register the systems' Risks and Sub Risks
              </div>
            </div>
            <RiskTable
              data={this.props.riskList}
              addNew={this.createRiskHandler}
              archive={this.props.handleArchiveRisk}
              export={this.exportRiskHandler}
              rights={this.props.rights}
            />
          </div>
        </div>
        {this.state.createNewRisk && (
          <div style={riskManagementStyle.RiskCreationFormWrapStyle}>
            <div style={riskManagementStyle.RiskCreationFormStyle}>
              <NewRiskForm
                parentRiskList={parentRisk}
                closeCreationForm={this.closeCreationForm}
                riskList={this.props.riskList}
                groupRiskList={this.props.groupRiskList}
                populateRiskDetail={this.props.populateRiskDetail}
                userList={this.props.userList}
                handleNewRiskSubmit={this.props.handleNewRiskSubmit}
                handleSearchGroupRisk={this.props.handleSearchGroupRisk}
                handleriskDetailRequest={this.props.handleriskDetailRequest}
                riskListFromGroup={this.props.riskListFromGroup}
                riskAutoPopulateDetail={this.props.riskAutoPopulateDetail}
                searching={this.props.searching}
              />
            </div>
          </div>
        )}
        {this.state.exportRisk && (
          <div style={{ ...riskManagementStyle.RiskCreationFormWrapStyle }}>
            <div style={riskManagementStyle.RiskCreationFormStyle}>
              <div
                style={{
                  ...riskManagementStyle.formBgStyle,
                  height: "40rem",
                  width: "50rem",
                  overflow: "hidden",
                  display: "block",
                }}
              >
                <div
                  style={{
                    ...riskManagementStyle.titleStyle,
                    color: "#00BEFF",
                    marginLeft: "5rem",
                    marginTop: "2rem",
                  }}
                >
                  Export {this.state.exportRiskIDList.length} selected Risks
                </div>
                {/*add choose format here*/}
                <div style={{ display: "inline-flex" }}>
                  <div>
                    <img
                      style={riskManagementStyle.previewImageStyle}
                      src={Excel_export_preview}
                      alt={"Export as Excel"}
                    />
                    <ButtonSolid
                      style={{ justifyContent: "center", display: "flex" }}
                      name={"Export as Excel"}
                      fontStyle={mapping["Font_Button_Badge_White"]}
                      size={"Small"}
                      width={"8rem"}
                      clickEvent={() => {
                        this.props.handleExportRisk(
                          this.state.exportRiskIDList,
                          "excel"
                        );
                      }}
                    />
                  </div>
                  <div>
                    <img
                      style={riskManagementStyle.previewImageStyle}
                      src={PDF_export_preview}
                      alt={"Export as PDF"}
                    />
                    <ButtonSolid
                      style={{ justifyContent: "center", display: "flex" }}
                      name={"Export as PDF"}
                      fontStyle={mapping["Font_Button_Badge_White"]}
                      size={"Small"}
                      width={"8rem"}
                      disabled={this.state.startExportingPDF}
                      clickEvent={() => {
                        this.props.resetRiskDetailList();
                        this.state.exportRiskIDList.map((id) => {
                          this.props.loadingRiskFunction(id);
                        });
                        this.setState({
                          exportFormat: "pdf",
                          startExportingPDF: true,
                        });
                      }}
                    />
                    <div
                      style={
                        this.state.startExportingPDF
                          ? {
                              fontSize: "1rem",
                              color: "#00BEFF",
                              justifyContent: "center",
                              display: "flex",
                            }
                          : {
                              visibility: "hidden",
                            }
                      }
                    >
                      Preparing Risk Report{" "}
                      {(
                        (this.state.exportProgress /
                          3 /
                          this.state.exportRiskIDList.length) *
                        100
                      ).toFixed(2)}
                      %...
                    </div>
                  </div>
                </div>
                <ButtonSolid
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    margin: "5rem",
                  }}
                  name={"cancel"}
                  fontStyle={mapping["Font_Button_Badge_White"]}
                  size={"Small"}
                  clickEvent={() => {
                    this.setState({
                      exportFormat: "excel",
                      exportRisk: false,
                      exportRiskIDList: [],
                    });
                  }}
                />
                {this.state.exportFormat === "pdf" && (
                  <ExportPDF
                    userList={this.props.userList}
                    export_risk_detail_list={this.props.riskDetailList}
                    updateExportProgress={(exportProgress)=>{this.setState({exportProgress:exportProgress})}}
                    finish={() => {
                      this.setState({ startExportingPDF: false });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {this.props.loading && (
          <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
        )}
      </div>
    );
  }
}

export { RiskManagement };
